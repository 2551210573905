import styles from './AllCars.module.css'
import { Car } from '../../components/Car/Car'
import { useCarsStore } from '../../store/carsStore'
import Grid from '@mui/material/Unstable_Grid2'
import { FormControl, Paper, Typography, CircularProgress } from '@mui/material'
import {
  FormContainer,
  SelectElement,
  useForm,
  useWatch,
} from 'react-hook-form-mui'
import { useEffect, useState } from 'react'
import FormDialog from '../../components/Popups/FormDialog'

const FormControlStyled = (props) => (
  <FormControl
    sx={{
      mr: 1,
      mt: 1,
      width: {
        xs: '100%',
        sm: '200px',
      },
    }}
    {...props}
  ></FormControl>
)

export function AllCars({
  openDialog,
  handleClickOpenDialog,
  handleCloseDialog,
  handleCloseDialogAndSaveLead,
}) {
  const cars = useCarsStore((store) => store.cars)
  const [filteredCars, setFilteredCars] = useState([])

  const searchAttributes = useCarsStore((store) => store.searchAttributes)

  const [selectedMakes, setSelectedMakes] = useState('')
  const [models, setModels] = useState([])

  const formContext = useForm({
    defaultValues: {
      makes: '',
      model: '',
      fuel: '',
      transmission: '',
      yearFrom: '',
      yearTo: '',
      mileageFrom: '',
      mileageTo: '',
    },
  })

  const filterFields = useWatch({ control: formContext.control })

  const filterCars = () => {
    setFilteredCars(
      cars.filter((car) => {
        const categoryFilterResult = [
          'makes',
          'model',
          'fuel',
          'transmission',
        ].reduce(
          (result, key) =>
            (result &= filterFields[key]
              ? car[key] === filterFields[key]
              : true),
          true
        )
        const mileageFilterResult =
          car['mileage'] >= (filterFields['mileageFrom'] || 0) &&
          car['mileage'] <= (filterFields['mileageTo'] || 10_000_000)

        const yearFilterResult =
          car['year'] >= (filterFields['yearFrom'] || 0) &&
          car['year'] <= (filterFields['yearTo'] || 9999)

        return categoryFilterResult && mileageFilterResult && yearFilterResult
      })
    )
  }

  useEffect(() => {
    filterCars()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cars])

  //модели машин
  useEffect(() => {
    if (!searchAttributes || !selectedMakes) return

    setModels(
      searchAttributes.makesAndModels.find((m) => m.id === selectedMakes).models
    )
  }, [searchAttributes, selectedMakes])

  if (!searchAttributes) return <CircularProgress color="success" />

  return (
    <>
      <main className={styles.wrapper}>
        <div className={styles.container}>
          <Typography variant="h5">Доступные для заказа автомобили</Typography>
          <Paper
            sx={{
              p: 1,
              my: 1,
              // display: 'grid',
              // gridTemplateColumns: 'auto 1fr',
            }}
            variant="outlined"
            className={styles.paper}
          >
            <FormContainer formContext={formContext} sx={{ m: 2 }}>
              {/* <Typography variant="body2" mb={1}>
                Фильтры:
              </Typography> */}

              <FormControlStyled>
                <SelectElement
                  size="small"
                  name="makes"
                  label="Марка"
                  options={searchAttributes?.makesAndModels ?? []}
                  type="string"
                  onChange={(value) => {
                    formContext.setValue('model', '')
                    setModels([])
                    setSelectedMakes(value)
                  }}
                  parseError={() => 'Это обязательное поле'}
                ></SelectElement>
              </FormControlStyled>

              <FormControlStyled>
                <SelectElement
                  size="small"
                  name="model"
                  label="Модель"
                  disabled={!models.length}
                  options={models || []}
                  type="string"
                  parseError={() => 'Это обязательное поле'}
                ></SelectElement>
              </FormControlStyled>

              <FormControlStyled>
                <SelectElement
                  size="small"
                  name="fuel"
                  label="Топливо"
                  options={searchAttributes?.fuelTypes ?? []}
                  type="string"
                ></SelectElement>
              </FormControlStyled>

              <FormControlStyled>
                <SelectElement
                  size="small"
                  name="transmission"
                  label="КПП"
                  options={searchAttributes?.transmissionTypes ?? []}
                  type="string"
                ></SelectElement>
              </FormControlStyled>
              {/* <br/> */}

              <FormControlStyled>
                <SelectElement
                  size="small"
                  name="mileageFrom"
                  label="Пробег от"
                  options={searchAttributes?.mileageStops ?? []}
                  type="string"
                ></SelectElement>
              </FormControlStyled>

              <FormControlStyled>
                <SelectElement
                  size="small"
                  name="mileageTo"
                  label="Пробег до"
                  options={searchAttributes?.mileageStops ?? []}
                  type="string"
                ></SelectElement>
              </FormControlStyled>

              <FormControlStyled>
                <SelectElement
                  size="small"
                  name="yearFrom"
                  label="Год от"
                  options={searchAttributes?.yearsStops ?? []}
                  type="string"
                ></SelectElement>
              </FormControlStyled>

              <FormControlStyled>
                <SelectElement
                  size="small"
                  name="yearTo"
                  label="Год до"
                  options={searchAttributes?.yearsStops ?? []}
                  type="string"
                ></SelectElement>
              </FormControlStyled>
            </FormContainer>
            <button
              className={styles.buttonSearch}
              onClick={filterCars}
              type="button"
            >
              Поиск
            </button>
          </Paper>

          <div className={styles.nofound}>
            {/* <Typography variant="h9" mb={1}>
              Не нашли нужный автомобиль? <br /> Оставьте заявку и наши
              менеджеры свяжутся с Вами в ближайшее время.
            </Typography> */}
            <button
              className={styles.buttonRequestConsultation}
              onClick={handleClickOpenDialog}
              type="button"
            >
              Не нашли нужный автомобиль?{' '}
              <span className={styles.underline}>Оставьте заявку</span> и наши
              менеджеры свяжутся с Вами в ближайшее время.{' '}
            </button>
          </div>

          <Grid container spacing={2} sx={{ mt: 1 }}>
            {filteredCars.map((car) => (
              <Car key={car._id} car={car} />
            ))}
          </Grid>
        </div>
      </main>

      <FormDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        handleSaveLead={handleCloseDialogAndSaveLead}
        title={'Получить бесплатную консультацию'}
      />
    </>
  )
}
