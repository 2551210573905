import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import api from '../../services/Api'
import { useNavigate } from 'react-router-dom'

export function Car({ car }) {
  const navigate = useNavigate()

  return (
    <Grid xs={12} md={4}>
      <Card>
        <CardActionArea onClick={() => navigate(`/cars/${car._id}`)}>
          {!!car.images.length && (
            <>
              <CardMedia
                component="img"
                height="250"
                image={api.getCarImageUrl(car?.images[0])}
                alt={`${car.makes} ${car.model}`}
                sx={{
                  '&.MuiCardMedia-root': {
                    objectFit: 'cover',
                    position: 'absolute',
                    filter: 'blur(20px) opacity(0.3)',
                    top: 0,
                  },
                  zIndex: 1,
                }}
              />

              <CardMedia
                component="img"
                height="250"
                image={api.getCarImageUrl(car?.images[0])}
                alt={`${car.makes} ${car.model}`}
                sx={{
                  '&.MuiCardMedia-root': {
                    objectFit: 'contain',
                    position: 'relative',
                    zIndex: 100,
                  },
                  mt: 2,
                }}
              />
            </>
          )}
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {car.makes} {car.model}
            </Typography>
            {car.price && (
              <Typography gutterBottom variant="body2" component="div">
                {car.price} WON
              </Typography>
            )}
            {car.priceRub && (
              <Typography gutterBottom variant="body2" component="div">
                {car.priceRub} RUB
              </Typography>
            )}
            {car.priceUsd && (
              <Typography gutterBottom variant="body2" component="div">
                {car.priceUsd} USD
              </Typography>
            )}
            {/* <Typography variant="body2" color="text.secondary">
              {car.description}
            </Typography> */}
          </CardContent>
        </CardActionArea>
        <CardActions sx={{ flexDirection: 'row-reverse' }}>
          <Button
            size="small"
            color="primary"
            onClick={() => navigate(`/cars/${car._id}`)}
          >
            Подробнее
          </Button>
        </CardActions>
      </Card>
    </Grid>
  )
}
