import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import api from '../services/Api'

export const useCarsStore = create(
  devtools(
    persist(
      (set, get) => ({
        cars: [],
        carsOps: {
          fetch: async () => {
            const cars = await api.getCars()
            set({ cars })
          },
          add: async (data) => {
            const car = await api.addCar(data)
            set({ cars: [car, ...get().cars] })
            return car
          },
          update: async (carId, data) => {
            const updatedCar = await api.updateCar(carId, data)
            set({
              cars: get().cars.map((c) => (c._id === carId ? updatedCar : c)),
            })
            return updatedCar
          },
          delete: async (id) => {
            await api.deleteCar(id)
            set({ cars: get().cars.filter((c) => c._id !== id) })
          },
          addImage: async (carId, data) => {
            const image = await api.addCarImage(carId, data)
            set({
              cars: get().cars.map((c) =>
                c._id === carId ? { ...c, images: [...c.images, image._id] } : c
              ),
            })
          },
          deleteImage: async (carId, imageId) => {
            await api.deleteCarImage(imageId)
            set({
              cars: get().cars.map((c) =>
                c._id === carId
                  ? { ...c, images: c.images.filter((id) => id !== imageId) }
                  : c
              ),
            })
          },
          updateInsuranceSheet: async (carId, data) => {
            const file = await api.updateCarInsuranceSheet(carId, data)
            set({
              cars: get().cars.map((c) =>
                c._id === carId ? { ...c, insuranceSheet: file._id } : c
              ),
            })
          },
        },

        searchAttributes: null,
        searchAttributesOps: {
          fetch: async () => {
            const emptyMenuItem = {
              id: '',
              label: '',
            }

            const searchAttributes = await api.getCarsSearchAttributes()

            searchAttributes.makesAndModels.forEach((makes) => {
              makes.models.unshift(emptyMenuItem)
            })
            searchAttributes.makesAndModels.unshift(emptyMenuItem)
            searchAttributes.mileageStops.unshift(emptyMenuItem)
            searchAttributes.yearsStops.unshift(emptyMenuItem)
            searchAttributes.fuelTypes.unshift(emptyMenuItem)
            searchAttributes.transmissionTypes.unshift(emptyMenuItem)

            set({ searchAttributes })
          },
        },

        auth: null,
        authOps: {
          login: async (data) => {
            const { ok, token } = await api.login(data)

            if (!ok) return false

            api.setAuthorizationToken(token)

            set({ auth: { token } })

            return true
          },
        },
      }),
      {
        name: 'auth',
        partialize: (state) => ({ auth: state.auth }),
        onRehydrateStorage: () => {
          return (state) => {
            api.setAuthorizationToken(state?.auth?.token)
          }
        },
      }
    )
  )
)
