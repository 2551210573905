import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { DialogContentText } from '@mui/material'

export default function CookieDialog({ open, handleClose, title }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: 14 }}>
          Чтобы наш сайт функционировал оптимально, и чтобы все страницы
          отображались правильно, необходимо, чтобы ваш браузер допускал файлы
          cookies. Файлы-куки используются для того, чтобы сайт смог узнавать
          посетителя на основе его предыдущих посещений, или, чтобы дать
          посетителям доступ к различным функциям или услугам на сайте, а также
          для предоставления статистических данных владельцам сайта. Если вы не
          хотите получать файлы cookies с нашего или других веб-сайтов, вы
          можете изменить настройки вашего браузера. Файл cookie — это небольшой
          текстовый файл, который веб-сайт сохраняет на вашем компьютере.
          Различные файлы-куки имеют своё предназначение. Например, файлы-куки
          используются для хранения пользовательских настроек для сайта.
          Файлы-куки могут также использоваться для ведения сайтом статистики
          посещений. В соответствии с Законом об электронной связи, все, кто
          посещает веб-сайт с куки-файлами, должны быть проинформированы о
          следующем: • Что сайт содержит куки-файлы • Для чего используются эти
          куки-файлы • Как можно избежать загрузки куки-файлов Имеется два типа
          файлов-куки: сессионные и постоянные. Сессионные файлы-куки
          сохраняются на вашем компьютере, но исчезают, как только вы покидаете
          сайт. Постоянные куки-файлы сохраняются на вашем компьютере до даты,
          когда файл-куки считается использованным. Хотите получить
          дополнительную информацию? Хотите узнать больше о том, что такое
          файлы-куки и что делать, чтобы избежать их? Посетите сайт
          информационного агентства почты и телекоммуникаций по адресу
          www.allaboutcookies.org.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
