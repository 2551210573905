import { Route, Routes } from 'react-router-dom'

import './App.css'
import { Main } from './pages/Main/Main'
import { Contacts } from './pages/Contacts/Contacts'
import { PageNotFound } from './components/PageNotFound/PageNotFound'
import { Export } from './pages/Export/Export'
import { Cars } from './pages/Cars/Cars'
import { Card } from './components/Card/Card'
import { Admin } from './pages/Admin/Admin'
import { AllCars } from './pages/AllCars/AllCars'
import { useCarsStore } from './store/carsStore'
import { useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { ruRU } from '@mui/material/locale'
import { Header } from './layout/Header/Header'
import { Footer } from './layout/Footer/Footer'
import { LogIn } from './pages/Admin/LogIn'
import api from './services/Api'

// console.log(process.env.NODE_ENV); // production

const theme = createTheme(
  {
    typography: {
      fontFamily: "'Inter', 'Roboto', 'Arial', 'sans-serif'",
    },
  },
  ruRU
)

export function App() {
  const fetchCars = useCarsStore(useShallow((state) => state.carsOps.fetch))
  const fetchSearchAttributes = useCarsStore(
    useShallow((state) => state.searchAttributesOps.fetch)
  )

  const [openDialog, setOpenDialog] = useState(false)
  const [openPolicy, setOpenPolicy] = useState(false)
  const [openCookie, setOpenCookie] = useState(false)



  //open-close info popup
  const handleClickOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleCloseDialogAndSaveLead = async (data) => {
    await api.saveLead(data)
    setOpenDialog(false)
  }

  //open-close policy popup
  const handleClickOpenPolicy = () => {
    setOpenPolicy(true);
  };
  const handleClosePolicy = () => {
    setOpenPolicy(false);
  };


  //open-close cookie popup
  const handleClickOpenCookie = () => {
    setOpenCookie(true);
  };
  const handleCloseCookie = () => {
    setOpenCookie(false);
  };


  useEffect(() => {
    fetchCars()
    fetchSearchAttributes()
  }, [fetchCars, fetchSearchAttributes])

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Routes>
        <Route path="/" element={<Main />}></Route>
        <Route path="/export" element={<Export />}></Route>
        <Route path="/contacts" element={<Contacts />}></Route>
        <Route path="*" element={<PageNotFound />}></Route>
        <Route path="/cars" element={<Cars />}></Route>
        <Route
          path="/cars/:id"
          element={
            <Card
              openDialog={openDialog}
              handleClickOpenDialog={handleClickOpenDialog}
              handleCloseDialog={handleCloseDialog}
              handleCloseDialogAndSaveLead={handleCloseDialogAndSaveLead}
            />
          }
        ></Route>
        <Route
          path="/avaliblity"
          element={
            <AllCars
              openDialog={openDialog}
              handleClickOpenDialog={handleClickOpenDialog}
              handleCloseDialog={handleCloseDialog}
              handleCloseDialogAndSaveLead={handleCloseDialogAndSaveLead}
            />
          }
        ></Route>
        <Route path="/admin" element={<Admin />}></Route>
        <Route path="/login" element={<LogIn />}></Route>
      </Routes>
      <Footer
        openPolicy={openPolicy}
        openCookie={openCookie}
        handleClickOpenPolicy={handleClickOpenPolicy}
        handleClickOpenCookie={handleClickOpenCookie}
        handleClosePolicy={handleClosePolicy}
        handleCloseCookie={handleCloseCookie} />
    </ThemeProvider>
  )
}
