import styles from './Admin.module.css'
import { useEffect, useState } from 'react'
import { AddCarPopup } from '../../components/Popups/AddCarPopup'
import api from '../../services/Api'
import { useCarsStore } from '../../store/carsStore'
import {
  Alert,
  Avatar,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Navigate } from 'react-router-dom'

export function Admin() {
  const isLoggedIn = useCarsStore((state) => !!state.auth)
  const cars = useCarsStore((state) => state.cars)
  const deleteCar = useCarsStore((state) => state.carsOps.delete)
  const [carId, setCarId] = useState(null)
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false)

  const getCarAvatarUrl = (car) =>
    car.images.length ? api.getCarImageUrl(car.images[0]) : ''

  //открываем попап добавления машины
  function openPopup(carId = null) {
    setCarId(carId)
    setIsAddPopupOpen(!isAddPopupOpen)
  }

  //закрываем  попап
  const closePopup = () => {
    setIsAddPopupOpen(false)
  }

  //закрываем на esc
  useEffect(() => {
    function closeByEscape(evt) {
      if (evt.key === 'Escape') {
        closePopup()
      }
    }
    if (isAddPopupOpen) {
      // навешиваем только при открытии
      document.addEventListener('keydown', closeByEscape)
      return () => {
        document.removeEventListener('keydown', closeByEscape)
      }
    }
  }, [isAddPopupOpen])

  if (!isLoggedIn) return <Navigate to={'/login'} />

  return (
    <>
      <main className={styles.wrapper}>
        <div className={styles.container}>
          <Typography sx={{ mb: 1 }} variant="h5">
            Список машин
          </Typography>
          <Paper sx={{ p: 1, my: 1 }} variant="outlined">
            <Button
              variant="text"
              // size={"small"}
              sx={{ width: 'fit-content' }}
              onClick={() => openPopup()}
            >
              Добавить
            </Button>
          </Paper>

          {!cars.length && (
            <Alert severity="info">Пока нет ни одной добавленной машины</Alert>
          )}

          <List
            sx={{ py: 0, my: 0, width: '100%', bgcolor: 'background.paper' }}
          >
            {cars.map((car) => {
              return (
                <ListItem
                  divider
                  key={car._id}
                  secondaryAction={
                    <>
                      <IconButton
                        sx={{ mr: 1 }}
                        edge="end"
                        aria-label="edit"
                        onClick={() => openPopup(car._id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          if (prompt('Вы уверены:?', 'Да!')) deleteCar(car._id)
                        }}
                        edge="end"
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  }
                  sx={{ py: 1, my: 1 }}
                  disablePadding
                >
                  <ListItemAvatar>
                    <Avatar alt={car.model} src={getCarAvatarUrl(car)} />
                  </ListItemAvatar>
                  {/* <ListItemIcon sx={{ justifyContent: 'center' }}>
                    <Tooltip title="В продаже" arrow placement="top">
                      <DirectionsCarIcon color="success" />
                    </Tooltip>
                  </ListItemIcon> */}

                  <ListItemText
                    id={car._id}
                    sx={{ mr: '100px' }}
                    primary={
                      <>
                        <span>
                          {car.makes} - {car.model}{' '}
                        </span>
                        <Typography
                          variant="caption"
                          sx={{ display: { xs: 'block', sm: 'inline' } }}
                        >
                          ({car.year}, {car.mileage}km, {car.price}won)
                        </Typography>
                      </>
                    }
                    secondary={car.description}
                  />
                </ListItem>
              )
            })}
          </List>
        </div>

        <AddCarPopup
          isOpenAddPopup={isAddPopupOpen}
          onCloseAddPopup={closePopup}
          carId={carId}
          setCarId={setCarId}
        />
      </main>
    </>
  )
}
