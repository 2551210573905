import { ImageList, ImageListItem } from '@mui/material'
import styles from './Main.module.css'
import { useNavigate } from 'react-router-dom'

export function Main() {
  const navigate = useNavigate()

  function moveToSearch() {
    navigate('/avaliblity')
  }

  const itemData = [
    {
      img: '/images/car_gallery/3.jpg',
      title: 'cardetail',
    },
    {
      img: '/images/car_gallery/2.jpg',
      title: 'cardetail',
    },
    {
      img: '/images/car_gallery/1.jpg',
      title: 'cardetail',
    },
    {
      img: '/images/car_gallery/8.jpg',
      title: 'cardetail',
    },
    {
      img: '/images/car_gallery/7.jpg',
      title: 'cardetail',
    },
    {
      img: '/images/car_gallery/5.jpg',
      title: 'cardetail',
    },
    {
      img: '/images/car_gallery/6.jpg',
      title: 'cardetail',
    },
    {
      img: '/images/car_gallery/4.jpg',
      title: 'cardetail',
    },
    {
      img: '/images/car_gallery/9.jpg',
      title: 'cardetail',
    },
  ]

  return (
    <>
      <main className={styles.wrapper}>
        <div className={styles.container}>
          <ImageList
            sx={{ width: 'auto', margin: 0, overflowY: 'auto' }}
            cols={3}
            rowHeight={164}
          >
            {itemData.map((item) => (
              <ImageListItem
                key={item.img}
                gap={5}
                sx={{ overflowY: 'hidden' }}
              >
                <img
                  srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>

          <div className={styles.text}>
            <div className={styles.wrapper_text}>
              <h1 className={styles.h1}>KoExportTeam</h1>
              <p className={styles.h2}>официальный автодилер в Южной Корее</p>
              <p className={styles.p}>
                Мы работаем на внутреннем рынке с 2003 года.
              </p>
              <p className={styles.p}>
                Мы продаём автомобили только принадлежащие нашей компании.
              </p>
              <p className={styles.p}>
                Наша компания гарантирует своим клиентам индивидуальный подход и
                профессионализм в работе.
              </p>
            </div>
            <button
              className={styles.button}
              onClick={moveToSearch}
              type="button"
            >
              ПОДОБРАТЬ АВТО
            </button>
          </div>
        </div>
      </main>
    </>
  )
}
