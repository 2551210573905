import styles from './Contacts.module.css'
import phone from '../../assets/phone.png'
import { useNavigate } from 'react-router-dom'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps'

export function Contacts() {
  const navigate = useNavigate()

  function moveToSearch() {
    navigate('/avaliblity')
  }

  return (
    <main>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <YMaps className={styles.pictures}>
            <Map
              className={styles.pictures}
              defaultState={{ center: [37.28, 127.0], zoom: 9 }}
            >
              <Placemark defaultGeometry={[37.280504, 127.002602]}> </Placemark>
            </Map>
          </YMaps>
          {/* <div className={styles.pictures}></div> */}
          <div className={styles.text}>
            <div className={styles.wrapper_text}>
              <h2 className={styles.h2}>
                Здесь мы находимся<br></br>и готовы помочь Вам ежедневно
              </h2>
              <p className={styles.p}>
                #285, 308-5 Gwonseon-ro Gwonseon-gu, Suwon-si, <br></br>
                Gyeonggi-do Republic of Korea
              </p>
              <ul className={styles.ul}>
                <li className={styles.li}>
                  <img className={styles.img} src={phone} alt="phones"></img>
                  +82-10-2508-1980
                </li>
                <li className={styles.li}>
                  <img className={styles.img} src={phone} alt="phones"></img>
                  +7 (950) 858-16-66 (Ростов на Дону)
                </li>{' '}
                <li className={styles.li}>
                  <img className={styles.img} src={phone} alt="phones"></img>
                  +7 (927) 500-76-58 (Краснодар)
                </li>
              </ul>
              <button
                className={styles.button}
                onClick={moveToSearch}
                type="button"
              >
                ПОИСК АВТОМОБИЛЯ
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
