import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { DialogContentText } from '@mui/material'

export default function PolicyDialog({ open, handleClose, title }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: 14 }}>
          Предоставляя свои персональные данные, Посетитель даёт согласие на
          обработку, хранение и использование своих персональных данных на
          основании ФЗ № 152-ФЗ «О персональных данных» от 27.07.2006 г. в
          следующих целях: Регистрации Пользователя на сайте; Осуществление
          клиентской поддержки; Получения Пользователем информации о
          маркетинговых событиях; Получение сведений для консультации и
          сотрудничества Проведения аудита и прочих внутренних исследований с
          целью повышения качества предоставляемых услуг. Под персональными
          данными подразумевается любая информация личного характера,
          позволяющая установить личность Посетителя такая как: Фамилия; Имя;
          Отчество; Дата рождения; Контактный телефон; Адрес электронной почты;
          Почтовый адрес. Персональные данные Посетителей хранятся исключительно
          на электронных носителях и обрабатываются с использованием
          автоматизированных систем, за исключением случаев, когда не
          автоматизированная обработка персональных данных необходима в связи с
          исполнением требований законодательства. Продавец обязуется не
          передавать полученные персональные данные третьим лицам, за
          исключением следующих случаев: По запросам уполномоченных органов
          государственной власти РФ только по основаниям и в порядке,
          установленным законодательством РФ Стратегическим партнерам, которые
          работают с Продавцом для предоставления продуктов и услуг, или тем из
          них, которые помогают Продавцу реализовывать продукты и услуги
          потребителям. Мы предоставляем третьим лицам минимальный объем
          персональных данных, необходимый только для оказания требуемой услуги
          или проведения необходимой транзакции. Продавец оставляет за собой
          право вносить изменения в одностороннем порядке в настоящие правила,
          при условии, что изменения не противоречат действующему
          законодательству РФ. Изменения условий настоящих правил вступают в
          силу после их публикации на Сайте.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
