// import { Link } from 'react-router-dom'
import styles from './Header.module.css'
import Navigation from '../Navigation/Navigation'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

export function Header() {
  const [isBurgerPopupOpen, setIsBurgerPopupOpen] = useState(false)

  //открываем бургер попап
  function burgerClick() {
    setIsBurgerPopupOpen(!isBurgerPopupOpen)
  }

  //закрываем бургер попап
  const closeBurgerPopup = () => {
    setIsBurgerPopupOpen(false)
  }

  //закрываем на esc
  useEffect(() => {
    function closeByEscape(evt) {
      if (evt.key === 'Escape') {
        closeBurgerPopup()
      }
    }
    if (isBurgerPopupOpen) {
      // навешиваем только при открытии
      document.addEventListener('keydown', closeByEscape)
      return () => {
        document.removeEventListener('keydown', closeByEscape)
      }
    }
  }, [isBurgerPopupOpen])

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <Link to="/">
          <img
            className={styles.logo}
            src={'/images/logo.png'}
            alt="logo"
          ></img>
        </Link>
        <Navigation
          isOpenBurger={isBurgerPopupOpen}
          changeBurgerView={burgerClick}
          onCloseBurgerPopup={closeBurgerPopup}
        />
      </div>
    </header>
  )
}
