import { Button, TextField } from '@mui/material'
import styles from './Admin.module.css'
import { useNavigate } from 'react-router-dom'
import { useCarsStore } from '../../store/carsStore'
import { useState } from 'react'

export const LogIn = () => {
  const navigate = useNavigate()
  const { login } = useCarsStore((state) => state.authOps)
  const { isLoggedIn } = useCarsStore((state) => !!state.auth)

  const [isLoginFailed, setIsLoginFailed] = useState(false)

  if (!!isLoggedIn) {
    navigate('/', { replace: true })
  }

  const handleLogin = async (evt) => {
    evt.preventDefault()
    const formData = new FormData(evt.target)
    const data = Object.fromEntries(formData.entries())
    const ok = await login(data)

    if (!ok) {
      setIsLoginFailed(true)

      return
    }

    navigate('/admin')
  }

  return (
    <main className={styles.wrapper}>
      <div className={styles.password}>
        <form name="login" onSubmit={handleLogin}>
          <TextField
            error={isLoginFailed}
            id="outlined-basic"
            label="Введите пароль"
            name="password"
            variant="outlined"
            type="password"
          />
          <Button sx={{ mt: 1 }} type="submit">
            Войти
          </Button>
        </form>
      </div>
    </main>
  )
}
