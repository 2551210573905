import styles from './Cars.module.css'
import { useNavigate } from 'react-router-dom'
import { Car } from '../../components/Car/Car'

export function Cars() {
  const navigate = useNavigate()

  function moveToSearch() {
    navigate('/search')
  }

  return (
    <>
      <main className={styles.wrapper}>
        <div className={styles.container}>
          <h5 className={styles.h5}>Результаты поиска</h5>

          <section className={styles.elements}>
            <ul className={styles.elementlist}>
              {/* {cards.map((card) => (
            <Card key={card._id} card={card} onCardClick={props.onCardClick} />
          ))} */}
              <Car />
            </ul>
          </section>

          <div className={styles.button_container}>
            <button
              className={styles.button}
              onClick={moveToSearch}
              type="button"
            >
              НОВЫЙ ПОИСК
            </button>
          </div>
        </div>
      </main>
    </>
  )
}
