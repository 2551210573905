import styles from './Card.module.css'
import { useParams } from 'react-router-dom'
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image'
import { useCarsStore } from '../../store/carsStore'
import api from '../../services/Api'
import FormDialog from '../Popups/FormDialog'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
export function Card({
  openDialog,
  handleClickOpenDialog,
  handleCloseDialogAndSaveLead,
  handleCloseDialog,
}) {
  const params = useParams()
  const searchAttributes = useCarsStore((store) => store.searchAttributes)
  const cars = useCarsStore((store) => store.cars)
  const car = cars.find((c) => c._id === params.id)

  if (!car || !searchAttributes) return <p></p>

  const hasSingleImage = car.images.length < 2

  const openInsurance = () => {
    window.open(api.getFileUrl(car.insuranceSheet), '_blank')
  }

  car.fuelLabel = searchAttributes.fuelTypes.find(
    (itm) => itm.id === car.fuel
  ).label

  car.transmissionLabel = searchAttributes.transmissionTypes.find(
    (itm) => itm.id === car.transmission
  ).label

  return (
    <>
      <main className={styles.wrapper}>
        <section className={styles.container}>
          <div className={styles.mainInfoContainer}>
            <div className={styles.img}>
              <Slide autoplay={!hasSingleImage} arrows={!hasSingleImage}>
                {car.images.map((imageId) => (
                  <div key={imageId} className={styles.eachslideeffect}>
                    {/* <div> */}
                    <img
                      className={styles.slideImage}
                      src={api.getCarImageUrl(imageId)}
                      alt="slide"
                    />
                    {/* </div> */}
                  </div>
                ))}
              </Slide>
            </div>

            <div className={styles.info}>
              <h1 className={styles.h1}>
                {car.makes} {car.model}
              </h1>
              <h2 className={styles.h2}>
                <Grid container columns={2} sx={{ width: 'min-content' }}>
                  <Grid xs={1}>₩</Grid>
                  <Grid xs={1}>{car.price}</Grid>
                  <Grid xs={1}>₽</Grid>
                  <Grid xs={1}>{car.priceRub}</Grid>
                  <Grid xs={1}>$</Grid>
                  <Grid xs={1}>{car.priceUsd}</Grid>
                </Grid>
              </h2>
              <ul className={styles.ul}>
                <li className={styles.li}>Модель: {car.model}</li>
                <li className={styles.li}>Пробег: {car.mileage} km</li>
                <li className={styles.li}>Год: {car.year}</li>
                <li className={styles.li}>Топливо: {car.fuelLabel}</li>
                <li className={styles.li}>КПП: {car.transmissionLabel}</li>
              </ul>
              {!!car.insuranceSheet && (
                <button
                  className={styles.button}
                  onClick={openInsurance}
                  type="button"
                >
                  СТРАХОВОЙ ЛИСТ
                </button>
              )}
              <button
                className={styles.button}
                onClick={handleClickOpenDialog}
                type="button"
              >
                ЗАКАЗАТЬ МАШИНУ
              </button>
            </div>
          </div>
          {!!car.description && (
            <div className={styles.descriptionContainer}>
              <Typography variant="h5" sx={{ mb: 1 }}>
                Дополнительная информация
              </Typography>
              <Typography sx={{ whiteSpace: 'pre-line', mb: 2 }}>
                {car.description}
              </Typography>
            </div>
          )}
        </section>
      </main>

      <FormDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        handleSaveLead={(data) =>
          handleCloseDialogAndSaveLead({ ...data, car })
        }
        title={'Заказать машину'}
      />
    </>
  )
}
