import styles from './AddCarPopup.module.css'
import Box from '@mui/material/Box'
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
  TextareaAutosizeElement,
} from 'react-hook-form-mui'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import CloseIcon from '@mui/icons-material/Close'

// import { ImageUploader } from '../ImageUploader/ImageUploader'
import { useState } from 'react'
import {
  Dialog,
  Divider,
  FormControl,
  Button,
  Typography,
  CircularProgress,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Alert,
  Link,
} from '@mui/material'
import { useCarsStore } from '../../store/carsStore'
import api from '../../services/Api'

const StepAddCar = ({ car, onSuccess, onCancel }) => {
  const searchAttributes = useCarsStore((state) => state.searchAttributes)
  const addCar = useCarsStore((state) => state.carsOps.add)
  const updateCar = useCarsStore((state) => state.carsOps.update)
  const [models, setModels] = useState(() =>
    car
      ? searchAttributes.makesAndModels.find((m) => m.id === car.makes).models
      : null
  )

  const handleMakesChange = (id) => {
    setModels(searchAttributes.makesAndModels.find((m) => m.id === id).models)
  }

  const handleSubmitCar = async (data) => {
    const updatedCar = !car
      ? await addCar(data)
      : await updateCar(car._id, data)
    // console.log(updatedCar);
    onSuccess(updatedCar)
  }

  if (!searchAttributes) return <CircularProgress color="success" />

  return (
    <FormContainer
      defaultValues={{
        makes: '',
        model: '',
        year: '',
        mileage: '',
        fuel: '',
        transmission: '',
        price: null,
        priceRub: null,
        priceUsd: null,
        ...car,
      }}
      onSuccess={handleSubmitCar}
      sx={{ m: 2 }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <FormControl sx={{ mt: 1, mb: 1, width: 'calc(50% - 10px)' }}>
          <SelectElement
            name="makes"
            label="Марка"
            options={searchAttributes.makesAndModels}
            type="string"
            onChange={handleMakesChange}
            parseError={() => 'Это обязательное поле'}
            required
            helperText="выберите марку машины"
          ></SelectElement>
        </FormControl>
        <FormControl sx={{ mt: 1, mb: 1, width: 'calc(50% - 10px)' }}>
          <SelectElement
            name="model"
            label="Модель"
            disabled={!models}
            options={models || []}
            type="string"
            parseError={() => 'Это обязательное поле'}
            required
            helperText="выберите модель машины"
          ></SelectElement>
        </FormControl>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <FormControl fullWidth sx={{ mt: 1, mb: 1, width: 'calc(50% - 10px)' }}>
          <TextFieldElement
            name="year"
            label="Год выпуска"
            type="number"
            parseError={() => 'Это обязательное поле'}
            required
            helperText="укажите год выпуска"
          ></TextFieldElement>
        </FormControl>
        <FormControl fullWidth sx={{ mt: 1, mb: 1, width: 'calc(50% - 10px)' }}>
          <TextFieldElement
            name="mileage"
            label="Пробег"
            type="number"
            parseError={() => 'Это обязательное поле'}
            required
            helperText="укажите пробег"
          ></TextFieldElement>
        </FormControl>
      </Box>

      <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
        <SelectElement
          name="fuel"
          label="Топливо"
          options={searchAttributes.fuelTypes}
          type="string"
          parseError={() => 'Это обязательное поле'}
          required
          helperText="выберите тип топлива"
        ></SelectElement>
      </FormControl>
      <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
        <SelectElement
          name="transmission"
          label="Трансмиссия"
          options={searchAttributes.transmissionTypes}
          type="string"
          parseError={() => 'Это обязательное поле'}
          required
          helperText="выберите тип трансмиссии"
        ></SelectElement>
      </FormControl>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <FormControl fullWidth sx={{ mt: 1, mb: 1, mr: 1 }}>
          <TextFieldElement
            name="price"
            label="Цена в WON"
            type="number"
            parseError={() => 'Это обязательное поле'}
            required
            helperText="укажите стоимость"
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: 1, mb: 1, mr: 1 }}>
          <TextFieldElement
            name="priceRub"
            label="Цена в RUB"
            type="number"
            parseError={() => 'Это обязательное поле'}
            required
            helperText="укажите стоимость"
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <TextFieldElement
            name="priceUsd"
            label="Цена в USD"
            type="number"
            parseError={() => 'Это обязательное поле'}
            required
            helperText="укажите стоимость"
          />
        </FormControl>
      </Box>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ textAlign: 'right' }}>
        <Button color="warning" onClick={onCancel}>
          Отменить
        </Button>
        <Button color="success" type="submit">
          Сохранить
        </Button>
        {car && <Button onClick={() => onSuccess(car)}>Далее</Button>}
      </Box>
    </FormContainer>
  )
}

const StepAddImage = ({ car, onBack, onSuccess, onCancel }) => {
  const addCarImage = useCarsStore((state) => state.carsOps.addImage)
  const deleteImage = useCarsStore((state) => state.carsOps.deleteImage)

  const onSubmitImage = async (evt) => {
    // console.log(el);

    const formData = new FormData()
    formData.append('image', evt.target.files[0])

    await addCarImage(car._id, formData)
    // alert(JSON.stringify(`${res.message}, status: ${res.status}`))
  }

  return (
    <>
      {car && car.images && !car.images.length && (
        <Alert severity="info">Пока нет ни одной добавленной фотографии</Alert>
      )}
      <ImageList
        sx={{ width: 'auto', height: 450 }}
        variant="quilted"
        cols={4}
        rowHeight={121}
        gap={10}
      >
        {car &&
          car.images &&
          car.images.map((imageId) => (
            <ImageListItem
              key={imageId}
              cols={1}
              rows={1}
              sx={{ '& .MuiImageListItem-img': { objectFit: 'contain' } }}
            >
              <img
                alt={car._id}
                src={api.getCarImageUrl(imageId)}
                loading="lazy"
              />
              <ImageListItemBar
                sx={{
                  background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, ' +
                    'rgba(0,0,0,0.2) 50%, rgba(0,0,0,0) 100%)',
                }}
                position="top"
                actionPosition="right"
                actionIcon={
                  <IconButton
                    onClick={() => deleteImage(car._id, imageId)}
                    sx={{
                      color: 'black',
                      backgroundColor: 'rgba(255,255,255,0.5)',
                      p: 0.1,
                      top: '5px',
                      right: '5px',
                    }}
                    aria-label={`star ${car.model}`}
                  >
                    <CloseIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
      </ImageList>
      <form>
        <label className={styles.input_file}>
          <input
            type="file"
            name="image"
            accept="image/*"
            onChange={onSubmitImage}
          />
          <span className={styles.input_file_btn}>Выберите файл</span>
          {/* <span className={styles.input_file_text}>Максимум 10мб</span> */}
        </label>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            color="inherit"
            // disabled={activeStep === 0}
            onClick={onBack}
            sx={{ mr: 1 }}
          >
            Назад
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button color="warning" onClick={onCancel}>
            Закрыть
          </Button>
          <Button onClick={onSuccess}>Далее</Button>
        </Box>
      </form>
    </>
  )
}

const StepAddDescription = ({ car, onSuccess, onBack }) => {
  const updateCar = useCarsStore((state) => state.carsOps.update)
  const updateCarInsuranceSheet = useCarsStore(
    (state) => state.carsOps.updateInsuranceSheet
  )

  const handleSubmitDescription = async (data) => {
    await updateCar(car._id, {
      description: data.description,
    })
    onSuccess()
  }

  const handeSubmitInsuranceSheet = async (evt) => {
    const formData = new FormData()
    formData.append('file', evt.target.files[0])

    await updateCarInsuranceSheet(car._id, formData)
  }

  if (!car) return <CircularProgress color="success" />

  return (
    <>
      <Typography sx={{ fontWeight: 'bold' }}>Страховой лист:</Typography>
      <form>
        {!!car.insuranceSheet && (
          <>
            <Link
              variant="overline"
              href={api.getFileUrl(car.insuranceSheet)}
              target="_blank"
            >
              Открыть
            </Link>
            {'  '}
          </>
        )}
        <label className={styles.input_file}>
          <input
            type="file"
            name="file"
            accept="application/pdf"
            onChange={handeSubmitInsuranceSheet}
          />
          <Link variant="overline" sx={{}}>
            Загрузить
          </Link>
        </label>
      </form>
      <FormContainer
        defaultValues={{
          description: car?.description,
        }}
        onSuccess={handleSubmitDescription}
        sx={{ m: 2 }}
      >
        <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
          Подробная инфрмация о машине:
        </Typography>
        <TextareaAutosizeElement
          autoComplete="false"
          autoCorrect="false"
          sx={{ width: '100%' }}
          name="description"
          rows={5}
          placeholder="Комплектация / особенности / другая информация (не обязательно)"
        ></TextareaAutosizeElement>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            color="inherit"
            // disabled={activeStep === 0}
            onClick={onBack}
            sx={{ mr: 1 }}
          >
            Назад
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          {/* <Button color="warning" onClick={onCancel}>
          Отменить
        </Button> */}
          <Button type="submit">Сохранить</Button>
        </Box>
      </FormContainer>
    </>
  )
}

function getSteps() {
  return ['Автомобиль', 'Картинки', 'Описание']
}

export function AddCarPopup({
  carId,
  setCarId,
  onCloseAddPopup,
  isOpenAddPopup: open,
}) {
  const [activeStep, setActiveStep] = useState(0)
  const car = useCarsStore((state) => state.cars.find((c) => c._id === carId))

  // console.log(car);

  const steps = getSteps()

  const handleClose = () => {
    onCloseAddPopup()
    setTimeout(() => setActiveStep(0), 200)
  }

  return (
    <Dialog onClose={handleClose} open={open} keepMounted={false}>
      <Box sx={{ width: '600px' }}>
        <Stepper sx={{ m: 2 }} activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {}
            const labelProps = {}

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <Divider />
        <Box sx={{ p: 2 }}>
          {activeStep === 0 && (
            <StepAddCar
              car={car}
              onSuccess={(car) => {
                setCarId(car._id)
                setActiveStep(1)
              }}
              onCancel={handleClose}
            />
          )}
          {activeStep === 1 && (
            <StepAddImage
              car={car}
              onSuccess={() => setActiveStep(2)}
              onBack={() => setActiveStep(activeStep - 1)}
              onCancel={handleClose}
            ></StepAddImage>
          )}
          {activeStep === 2 && (
            <StepAddDescription
              car={car}
              onSuccess={handleClose}
              onBack={() => setActiveStep(activeStep - 1)}
            ></StepAddDescription>
          )}
        </Box>
      </Box>
    </Dialog>
  )
}
