import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, DialogContentText } from '@mui/material'

export default function FormDialog({
  open,
  handleClose,
  handleSaveLead,
  title,
}) {
  const handleSubmit = (evt) => {
    evt.preventDefault()

    const data = Object.fromEntries(new FormData(evt.target).entries())

    handleSaveLead(data)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: 14 }}>
          {/* Отправляя форму, вы соглашаетесь с тем, что  */}
          Оставьте свой email и вопросы в форме ниже, и мы свяжемся с Вами.
          <br />
          Также, мы на связи через мессенджер.
        </DialogContentText>
        <Box component="form" id="lead-dialog" onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            name="comment"
            label="Комментарий"
            type="text"
            fullWidth
            variant="standard"
            multiline={true}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Отмена</Button>
        <Button type="submit" form="lead-dialog">
          Отправить
        </Button>
      </DialogActions>
    </Dialog>
  )
}
