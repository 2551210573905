import styles from './Footer.module.css'
import phones from '../../assets/phones_icons.png'
import { Link } from 'react-router-dom'
import CookieDialog from '../../components/Popups/CookieDialog'
import PolicyDialog from '../../components/Popups/PolicyDialog'

export function Footer({
  openPolicy,
  openCookie,
  handleClickOpenPolicy,
  handleClickOpenCookie,
  handleClosePolicy,
  handleCloseCookie,
}) {
  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.box}>
          <div className={styles.left_container}>
            <img
              className={styles.logo}
              src={'/images/logo.png'}
              alt="logo"
            ></img>
            <div className={styles.tech_links}>
              <Link to={'/admin'} className={styles.link}>
                Adm
              </Link>
              <span className={styles.link} onClick={handleClickOpenPolicy}>
                Политика конфиденциальности
              </span>
              <span className={styles.link} onClick={handleClickOpenCookie}>
                Правила cookie
              </span>
            </div>
          </div>

          <div className={styles.container}>
            <span className={styles.li}>
              <img className={styles.img} src={phones} alt="phones" />
              +82-10-2508-1980
            </span>
            <span className={styles.li}>
              +7 (950) 858-16-66 (Ростов на Дону)
            </span>
            <span className={styles.li}>+7 (927) 500-76-58 (Краснодар)</span>
          </div>
        </div>
      </footer>
      <CookieDialog
        open={openCookie}
        handleClose={handleCloseCookie}
        title={'Правила cookie'}
      />
      <PolicyDialog
        open={openPolicy}
        handleClose={handleClosePolicy}
        title={'Политика конфиденциальности'}
      />
    </>
  )
}
