import styles from './Export.module.css'

import hunday from '../../images/cars_logo/hunday.png'
import audi from '../../images/cars_logo/audi.png'
import bmw from '../../images/cars_logo/bmw.png'
import kia from '../../images/cars_logo/kia.png'
import lr from '../../images/cars_logo/land-rover.png'
import lexus from '../../images/cars_logo/lexus.webp'
import mers from '../../images/cars_logo/mers.png'
import toyota from '../../images/cars_logo/toyota.png'

import { useNavigate } from 'react-router-dom'
import { ImageList, ImageListItem } from '@mui/material'

export function Export() {
  const navigate = useNavigate()

  function moveToSearch() {
    navigate('/avaliblity')
  }

  const itemData = [
    {
      img: '/images/cars_export/3.jpg',
      title: 'cardetail',
    },
    {
      img: '/images/cars_export/7.jpg',
      title: 'cardetail',
    },
    {
      img: '/images/cars_export/4.jpg',
      title: 'cardetail',
    },
    {
      img: '/images/cars_export/8.jpg',
      title: 'cardetail',
    },
    {
      img: '/images/cars_export/2.jpg',
      title: 'cardetail',
    },
    {
      img: '/images/cars_export/5.jpg',
      title: 'cardetail',
    },
    {
      img: '/images/cars_export/6.jpg',
      title: 'cardetail',
    },
    {
      img: '/images/cars_export/9.jpg',
      title: 'cardetail',
    },
    {
      img: '/images/cars_export/1.jpg',
      title: 'cardetail',
    },
  ]

  return (
    <>
      <main className={styles.wrapper}>
        <div className={styles.container}>
          <ImageList
            sx={{ width: 'auto', margin: 0, overflowY: 'auto' }}
            cols={3}
            rowHeight={164}
          >
            {itemData.map((item) => (
              <ImageListItem
                key={item.img}
                gap={5}
                sx={{ overflowY: 'hidden' }}
              >
                <img
                  srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>

          <div className={styles.text}>
            <div className={styles.wrapper_text}>
              <h2 className={styles.h2}>
                С 2023 года наша компания занимается экспортом своих автомобилей
                из Южной Кореи в Россию
              </h2>
              <div className={styles.p}>
                Вы можете заказать у нас новый автомобиль под заказ таких марок
                как:
                <ul className={styles.ul}>
                  <li className={styles.li}>
                    <img
                      className={styles.img_logo}
                      src={mers}
                      alt="logo"
                    ></img>
                    Mersedes-Benz
                  </li>
                  <li className={styles.li}>
                    <img
                      className={styles.img_logo}
                      src={hunday}
                      alt="logo"
                    ></img>
                    Hyundai
                  </li>
                  <li className={styles.li}>
                    <img
                      className={styles.img_logo}
                      src={audi}
                      alt="logo"
                    ></img>
                    Audi
                  </li>
                  <li className={styles.li}>
                    <img
                      className={styles.img_logo}
                      src={toyota}
                      alt="logo"
                    ></img>
                    Toyota
                  </li>
                  <li className={styles.li}>
                    <img
                      className={styles.img_logo}
                      src={lexus}
                      alt="logo"
                    ></img>
                    LEXUS
                  </li>
                  <li className={styles.li}>
                    <img className={styles.img_logo} src={bmw} alt="logo"></img>
                    BMW
                  </li>
                  <li className={styles.li}>
                    <img className={styles.img_logo} src={kia} alt="logo"></img>
                    KIA
                  </li>
                  <li className={styles.li}>
                    <img className={styles.img_logo} src={lr} alt="logo"></img>
                    Land Rover
                  </li>
                </ul>
                Мы сотрудничаем с транспортно-логистической компанией Альтерна,
                которая оказывает весь комплекс услуг в области ВЭД и
                транспортировке автомобилей.
              </div>
            </div>
            <button
              className={styles.button}
              onClick={moveToSearch}
              type="button"
            >
              ПОИСК АВТОМОБИЛЯ
            </button>
          </div>
        </div>
      </main>
    </>
  )
}
