const _response = (res) => {
  if (res.ok) {
    return res.json()
  }
  return res.json().then(({ message, error }) => {
    res.message = message || error || `Закралась ошибка: ${res.status}`
    return Promise.reject(res)
  })
}

class Api {
  constructor(options) {
    this.options = options
  }

  setAuthorizationToken(token) {
    this.options.headers['Authorization'] = 'Bearer ' + token
  }

  getCarsSearchAttributes() {
    return fetch(`${this.options.baseUrl}/cars/search-attributes`, {
      method: 'GET',
      headers: this.options.headers,
    })
      .then(_response)
      .then((data) => ({
        ...data,
        mileageStops: data.mileageStops.map((el) => ({ id: el, label: el })),
        yearsStops: data.yearsStops.map((el) => ({ id: el, label: el })),
      }))
  }

  getCars() {
    return fetch(`${this.options.baseUrl}/cars/search`, {
      method: 'POST',
      headers: this.options.headers,
    })
      .then(_response)
      .then((cars) => {
        return cars.map((c) => ({
          ...c,
          id: c.carId,
        }))
      })
  }

  addCar(car) {
    return fetch(`${this.options.baseUrl}/cars`, {
      method: 'POST',
      headers: this.options.headers,
      body: JSON.stringify(car),
    }).then(_response)
  }

  updateCar(carId, data) {
    return fetch(`${this.options.baseUrl}/cars/${carId}`, {
      method: 'PUT',
      headers: this.options.headers,
      body: JSON.stringify(data),
    }).then(_response)
  }

  deleteCar(carId) {
    return fetch(`${this.options.baseUrl}/cars/${carId}`, {
      method: 'DELETE',
      headers: this.options.headers,
    }).then(_response)
  }

  addCarImage(carId, data) {
    return fetch(`${this.options.baseUrl}/cars/${carId}/images`, {
      method: 'POST',
      headers: { Authorization: this.options.headers['Authorization'] },
      body: data,
    }).then(_response)
  }

  getCarImageUrl(imageId) {
    return `${this.options.baseUrl}/images/${imageId}`
  }

  deleteCarImage(imageId) {
    return fetch(`${this.options.baseUrl}/images/${imageId}`, {
      method: 'DELETE',
      headers: this.options.headers,
    }).then(_response)
  }

  updateCarInsuranceSheet(carId, data) {
    return fetch(`${this.options.baseUrl}/cars/${carId}/insuranceSheet`, {
      method: 'POST',
      headers: { Authorization: this.options.headers['Authorization'] },
      body: data,
    }).then(_response)
  }

  getFileUrl(fileId) {
    return `${this.options.baseUrl}/files/${fileId}`
  }

  login(data) {
    return fetch(`${this.options.baseUrl}/auth/login`, {
      method: 'POST',
      headers: this.options.headers,
      body: JSON.stringify(data),
    }).then(_response)
  }

  saveLead(data) {
    return fetch(`${this.options.baseUrl}/lead`, {
      method: 'POST',
      headers: this.options.headers,
      body: JSON.stringify(data),
    }).then(_response)
  }
}

const api = new Api({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: '',
  },
})

export default api
