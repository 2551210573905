import { Button } from '../Button/Button'
import styles from './PageNotFound.module.css'
import { useNavigate } from 'react-router-dom'

export function PageNotFound() {
  const navigate = useNavigate()

  function moveToSearch() {
    navigate('/avaliblity')
  }

  return (
    <>
      <main className={styles.wrapper}>
        <div className={styles.container}>
          <p className={styles.error}>404</p>
          <p className={styles.p}>
            Упс, на этой странице нет автомобилей. Попробуйте тут
          </p>
          <Button text={'ПОИСК АВТОМОБИЛЯ'} onClick={moveToSearch} />
        </div>
      </main>
    </>
  )
}
