import { Link } from 'react-router-dom'
import styles from './Navigation.module.css'
import classNames from 'classnames'

function Navigation({ onCloseBurgerPopup, isOpenBurger, changeBurgerView }) {
  return (
    <>
      <nav className={styles.navigation__container}>
        <div className={styles.navigation__container_hide}>
          <Link className={styles.link} to="/avaliblity">
            КАТАЛОГ АВТО
          </Link>
          <Link className={styles.link} to="/export">
            ЭКСПОРТ
          </Link>
          <Link className={styles.link} to="/contacts">
            КОНТАКТЫ
          </Link>
        </div>
      </nav>
      <button
        className={styles.navigation__burger_button}
        onClick={changeBurgerView}
        type="button"
      ></button>
      <nav
        className={classNames(styles.navigation__burger, {
          [styles.navigation__burger_opened]: isOpenBurger,
        })}
      >
        <div className={styles.navigation__burger_container}>
          <button
            type="button"
            onClick={onCloseBurgerPopup}
            className={styles.navigation__burger_close}
          ></button>

          <div>
            <Link className={styles.navigation__links} to="/export">
              ЭКСПОРТ
            </Link>
            <Link className={styles.navigation__links} to="/avaliblity">
              КАТАЛОГ АВТО
            </Link>
            <Link className={styles.navigation__links} to="/contacts">
              КОНТАКТЫ
            </Link>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navigation
